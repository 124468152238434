<template>
        <div class="container">

            <template >
                <div class="email_offer__wrap">
                    <div class="email_offer__title">
                        После детального изучения ваших данных, Вам были подобраны займы,
                        которые с наибольшей вероятностью будут одобрены.
                    </div>
                </div>
                <div class="email_offer__wrap">
                    <button type="button" @click="showAllEmailOffers=true"  v-if="!showAllEmailOffers"  class="btn email_offer__btn">Открыть список</button>
                </div>

                <EmailOffersList  :offers="allOffers"  :query="query" v-if="showAllEmailOffers"/>
            </template>
        </div>

</template>
<script>
    import EmailOfferItem from '../components/EmailOfferItem.vue';
    import EmailOffersList from "../components/EmailOffersList.vue";

    export default {
        components: {
            EmailOffersList,
            EmailOfferItem
        },
        props: {
            url: String,
            query: String
        },
        data() {
            return {
                info: null,
                errored: false,
                allOffers: null,
                showAllEmailOffers: false
            }
        },
        mounted() {

            axios.get(this.url).then(response => {
                this.info = response.data || {};
                this.allOffers = this.info[Object.keys(this.info)[0]];

            }).catch(error => {
                console.error(error)
                this.errored = true
            });
        },

    }
</script>