<template>
    <div class="container">
        <transition name="slide" mode="out-in">
            <div class="overlay" v-if="showForm || showSuccess" @click="close">
                <div :class="[
                    'overlay__container',
                    showForm ? 'overlay__container_form' : 'overlay__container_success'
                ]">
                    <reviewer-form
                        v-if="showForm"
                        :siteId="siteId"
                        @success="successHandler"
                        @close="close"
                    />

                    <reviewer-success @close="close" v-if="showSuccess" />
                </div>
            </div>
        </transition>

        <button class="reviewer-opener g-button" @click="openForm">
            Оставить отзыв
        </button>
    </div>
</template>

<script>
import ReviewerForm from './ReviewerForm.vue';
import ReviewerSuccess from './ReviewerSuccess.vue';

export default {
    name: 'Reviewer',
    props: {
        siteId: {
            type: [Number, String],
            required: true,
        },
    },
    components: {
        ReviewerForm,
        ReviewerSuccess,
    },
    data() {
        return {
            showForm: false,
            showSuccess: false,
        };
    },
    methods: {
        successHandler() {
            this.showForm = false;
            this.showSuccess = true;
        },
        openForm() {
            this.showForm = true;
            this.setDocumentListeners();
        },
        close() {
            this.showForm = false;
            this.showSuccess = false;
            this.removeDocumentListeners();
        },
        setDocumentListeners() {
            document.body.style.overflow = 'hidden';
            window.addEventListener('keydown', this.closeOnKeydown);
        },
        removeDocumentListeners(){
            document.body.style.overflow = 'unset';
            window.removeEventListener('keydown', this.closeOnKeydown);
        },
        closeOnKeydown(event) {
            if (event.keyCode === 27) {
                this.close();
            }
        }
    },
};
</script>
