<template>
  <input type="text" v-model="value" :placeholder="placeholder" @blur="onBlur()">
</template>

<script>
  import $ from 'jquery';
  import 'suggestions-jquery';
  export default {
    props: {
      model: {
        required: true
      },
      coordinates: {},
      options: {
        type: Object,
        default: {
          type: 'ADDRESS',
          addon: 'none'
        }
      },
      placeholder: {String}
    },
    data() {
      return {
		  nope: 'nope',
        value: '',
        coords: {
          latitude: '',
          longitude: ''
        },
      }
    },
    mounted() {
      this.callbacks = $.Callbacks();
      this.value = this.model;
      this.initSuggestion();
    },
    destroyed() {
      this.destroySuggestion();
    },
    watch: {
      coords: {
        handler() {
          this.$emit('update:coordinates', this.coords);
        },
        deep: true
      },
      value() {
        this.$emit('update:model', this.value);
      },
      model() {
        this.value = this.model;
      }
    },
    methods: {
      onBlur(){
        if(this.value=='')
          this.value=null
      },
      initSuggestion() {
        this.callbacks.add(this.onSelect);
        this.callbacks.add(this.options.onSelect || $.noop)
        const options = Object.assign({}, this.options, {
          onSelect: suggestion => this.callbacks.fire(suggestion)
        });
        $(this.$el).suggestions({
            ...options,
            scrollOnFocus: false,
        });
      },
      destroySuggestion() {
        const plugin = $(this.$el).suggestions();
        plugin.dispose();
      },
      onSelect(suggestion) {
        if(suggestion.data.name){
          if(suggestion.data.gender === "MALE")
            this.$root.globalGender='М'
          else if(suggestion.data.gender === "FEMALE")
            this.$root.globalGender='Ж'
          else
            this.$root.globalGender='НД'
        }

        if (this.options.bounds&&this.options.bounds=='city-settlement'){
          this.$root.globalCityKladr=suggestion.data.kladr_id
          this.$root.globalRegion=suggestion.data.region_with_type
        }


        this.value = suggestion.value;
        const { geo_lat, geo_lon } = suggestion.data;
        this.coords.latitude = geo_lat;
        this.coords.longitude = geo_lon;
      }
    }
  };
</script>
