<template>
	<section>
		<div class="container">
			<h2>{{ title }}</h2>
			<List :items="items" />
			<slot></slot>
			<a href="/application" class="featers__btn btn" v-if="btnShow">Оформить заявку</a>
		</div>
	</section>
</template>
<script>
import List from "./List.vue"
export default {
	components: {
			List
	},
	props:  {
		title : {String},
		items: {Object},
		btnShow: {Boolean}
	},
	data() {
		return {

		}
	}
}
</script>
