<template>
    <select :value="selTerm" class="credit-form__input credit-form__input--big" required @change="changeSelect($event.target.value)">
        <option v-for="(term, index) in terms" :value="term" :key="index" :selected="term == selTerm">
            {{term | translates(term)}}
        </option>
    </select>
</template>
<script>
export default {
    props: {
        selTerm: String,
        count: Number
    },
    data() {
        return {
            terms: ['day', 'month']
        }
    },
    filters: {
        translates(value) {
            if (value === 'month') return value.replace('month', 'месяц');
            if (value === 'day') return value.replace('day', 'день');
        },
    },
    methods: {
        changeSelect(val) {
            this.$emit('change', val);
        }
    }
}
</script>