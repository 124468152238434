<template>
    <a :href="url" class="offer" ref="offer" target="_blank">
        <span class="offer__lable" ref="labelOffers">{{offer.label}}</span>
        <a :href="url" class="offer__link">
            <img :src="offer.logo" width="250" height="85">
        </a>
        <h3 class="offer__title">{{ offer.displayName }}</h3>
        <div class="offer__items-wrap">
            <p class="offer__item">
                <span class="offer__name">Сумма</span>
                <span class="offer__value">до {{(offer.newSumTo).toLocaleString('ru')}} &#8381;</span>
            </p>
            <p class="offer__item">
                <span class="offer__name offer__name--xl">Процентная ставка</span>
                <span class="offer__name offer__name--sm">Проценты</span>
                <span class="offer__value">от {{offer.newPercentFrom}} %</span>
            </p>
            <div class="offer__item-wrap">
                <p class="offer__item">
                    <span class="offer__name">Возраст</span>
                    <span class="offer__value">от {{offer.newAgeFrom}} лет</span>
                </p>
                <p class="offer__item">
                    <span class="offer__name">Срок</span>
                    <span class="offer__value">до {{offer.newTermTo}} дней</span>
                </p>
            </div>
        </div>
        <a :href="url" class="offer__btn btn" target="_blank">Оформить заявку</a>
    </a>
</template>
<script>
    export default {
        props: {
            offer: Object,
            query: String
        },
        data() {
            return {
                url: ''
            }
        },
        mounted() {
            this.getUrl();
            if (!this.offer.label) {
                this.$refs.labelOffers.style.display = "none";
            } else {
                switch (this.offer.labelColor) {
                    case 'green':
                        this.$refs.offer.classList.add('offer--green');
                        break;
                    case 'blue':
                        this.$refs.offer.classList.add('offer--blue');
                        break;
                    case 'orange':
                        this.$refs.offer.classList.add('offer--orange');
                        break;
                    case 'purple':
                        this.$refs.offer.classList.add('offer--purple');
                        break;

                    default:
                        this.$refs.labelOffers.style.display = "none";
                }
            }
        },
        methods: {
            makeParamsMap(query) {
                return query.split('&')
                    .reduce((acc, param) => {
                        const [key, value] = param.split('=');
                        if (value) {
                            acc[key] = value;
                        }
                        return acc;
                    }, {});
            },
            getUrl() {
                let smLinkComponents = this.offer.trackingLink.split("?");
                let smUrl = smLinkComponents[0];

                let smParams = this.makeParamsMap(smLinkComponents[1])
                let ourParams = this.makeParamsMap(this.query)

                let finalParams = { ...smParams, ...ourParams };
                const serializedParams = Object.keys(finalParams).reduce((params, key) => {
                    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(finalParams[key])}`);
                    return params;
                }, []).join('&');
                this.url = `${smUrl}?${serializedParams}`;
            }
        },
    }
</script>