<template>
    <form class="reviewer-form" @submit.prevent="submit" @click.stop>
        <div class="reviewer-form__close" @click="$emit('close')"></div>
        <div class="reviewer-form__title">Оставить отзыв</div>

        <div class="reviewer-group">
            <div class="reviewer-group__control">
                <label class="reviewer-group__label" for="reviewerName">Ваше имя (ФИО)*</label>
                <input
                    id="reviewerName"
                    type="text"
                    name="name"
                    class="reviewer-group__input"
                    :class="{ 'reviewer-group__input_error': $v.model.name.$error }"
                    placeholder="Введите имя"
                    v-model="model.name"
                    @blur="$v.model.name.$touch()"
                />
                <template v-if="$v.model.name.$error">
                    <div class="reviewer-form__error" v-if="!$v.model.name.required">Заполните поле</div>
                    <div class="reviewer-form__error" v-if="!$v.model.name.minLength || !$v.model.name.maxLength">
                        Введите корректное имя
                    </div>
                </template>
            </div>
            <div class="reviewer-group__control">
                <label class="reviewer-group__label" for="reviewerEmail">E-mail*</label>
                <input
                    id="reviewerEmail"
                    type="text"
                    name="email"
                    class="reviewer-group__input"
                    :class="{ 'reviewer-group__input_error': $v.model.email.$error }"
                    placeholder="E-mail*"
                    v-model="model.email"
                    @blur="$v.model.email.$touch()"
                />

                <template v-if="$v.model.email.$error">
                    <div class="reviewer-form__error" v-if="!$v.model.email.required">Заполните поле</div>
                    <div class="reviewer-form__error" v-if="!$v.model.email.minLength || !$v.model.email.maxLength || !$v.model.email.email">
                        Введите корректный email
                    </div>
                </template>
            </div>
        </div>

        <div class="reviewer-rating">
            <label class="reviewer-rating__label">Ваша оценка*</label>
            <div class="reviewer-rating__group">
                <input
                    type="radio"
                    name="rating"
                    id="rating-1"
                    class="reviewer-rating__input"
                    value="1"
                    v-model="model.rating"
                    @blur="$v.model.rating.$touch()"
                >
                <label for="rating-1" class="reviewer-rating__item"></label>

                <input
                    type="radio"
                    name="rating"
                    id="rating-2"
                    class="reviewer-rating__input"
                    value="2"
                    v-model="model.rating"
                    @blur="$v.model.rating.$touch()"
                >
                <label for="rating-2" class="reviewer-rating__item"></label>

                <input
                    type="radio"
                    name="rating"
                    id="rating-3"
                    class="reviewer-rating__input"
                    value="3"
                    v-model="model.rating"
                    @blur="$v.model.rating.$touch()"
                >
                <label for="rating-3" class="reviewer-rating__item"></label>

                <input
                    type="radio"
                    name="rating"
                    id="rating-4"
                    class="reviewer-rating__input"
                    value="4"
                    v-model="model.rating"
                    @blur="$v.model.rating.$touch()"
                >
                <label for="rating-4" class="reviewer-rating__item"></label>

                <input
                    type="radio"
                    name="rating"
                    id="rating-5"
                    class="reviewer-rating__input"
                    value="5"
                    v-model="model.rating"
                    @blur="$v.model.rating.$touch()"
                >
                <label for="rating-5" class="reviewer-rating__item"></label>
            </div>

            <template v-if="$v.model.rating.$error">
                <div class="reviewer-form__error" v-if="!$v.model.rating.required">Укажите оценку</div>
            </template>
        </div>

        <div class="reviewer-message">
            <label class="reviewer-message__label" for="reviewerMessage">Комментарий*</label>
            <textarea
                name="message"
                id="reviewerMessage"
                class="reviewer-message__area"
                :class="{ 'reviewer-message__area_error': $v.model.message.$error }"
                v-model="model.message"
                @blur="$v.model.message.$touch()"
            />

            <template v-if="$v.model.message.$error">
                <div class="reviewer-form__error" v-if="!$v.model.message.required">Напишите отзыв</div>
                <div class="reviewer-form__error" v-if="!$v.model.message.minLength">
                    Напишите более подробно
                </div>
            </template>
        </div>

        <div class="reviewer-policy">
            <div class="reviewer-policy__wrapper">
                <div class="reviewer-policy__control">
                    <input
                        id="policyCheckbox"
                        type="checkbox"
                        class="reviewer-policy__checkbox"
                        v-model="model.agree"
                        @input="$v.model.agree.$touch()"
                    />
                    <label for="policyCheckbox" class="reviewer-policy__view"></label>
                </div>
                <label for="policyCheckbox" class="reviewer-policy__text">
                    Я принимаю <a href="/agreement" class="reviewer-policy__link" target="_blank">условия передачи информации</a>
                </label>
            </div>

            <template v-if="$v.model.agree.$error">
                <div class="reviewer-form__error" v-if="!$v.model.agree.checked">Необходимо согласие</div>
            </template>
        </div>

        <input type="submit" class="reviewer-submit g-button" value="Отправить">
    </form>
</template>

<script>
import {
    required,
    email,
    minLength,
    maxLength,
} from 'vuelidate/lib/validators';

export default {
    name: 'ReviewerForm',
    props: {
        siteId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            model: {
                agree: false,
                rating: null,
                name: '',
                email: '',
                message: '',
            }
        };
    },
    validations: {
        model: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(35),
            },
            email: {
                required,
                email,
                minLength: minLength(2),
                maxLength: maxLength(35),
            },
            rating: {
                required,
            },
            message: {
                required,
                minLength: minLength(4),
            },
            agree: {
                checked: (val) => val,
            },
        },
    },
    methods: {
        submit() {
            if (this.$v.$invalid) {
                return Object.keys(this.model).forEach((key) => this.$v.model[key].$touch());
            }

            $.post('/post_review', {
                'site_id': this.siteId,
                rating: this.model.rating,
                'reviewer_name': this.model.name,
                'review_text': this.model.message,
                'reviewer_email': this.model.email,
            }, (response) => {
                if (response.status === 'error') {
                    console.error(`Error sending feedback with message: ${response.message}`);
                }
                this.$emit('success');
            });
        },
    },
};
</script>
