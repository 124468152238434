<template>
  <div>
    <Banner />
    <featers
      title="Получите деньги за 15 минут"
      :items="featers"
      class="featers featers--white featers--steps"
      :btnShow="true"
      id="howDoIt"
    >
      <p class="featers__text">
        Сервис Unizaym поможет Вам получить займы до 100.000 рублей на срок до
        12 месяцев. Для получения денежных средств свыше 30.000 рублей, как
        правило, займ оформляется на срок от 2 до 12 месяцев.
      </p>
      <p class="featers__text">
        *Процентная ставка по займам начинается от 0% при условии прохождения
        акции "Займ под 0% для новых клиентов". Сервис подберет для Вас наиболее
        выгодные решения денежного вопроса. Более подробную информацию уточняйте
        у конечных кредиторов.
      </p>
    </featers>
    <featers
      title="Требования к заемщикам"
      :items="requirements"
      class="featers featers--requirements"
      :btnShow="false"
    >
      <p class="featers__text">
        Займы выдаются гражданам Российской Федерации в возрасте от 18 лет.
      </p>
      <p class="featers__text">
        В случаях займа на длительный срок (более одного месяца) и сумме более
        30.000 рублей ограничение по возрасту составит 21 год.
      </p>
      <p class="featers__text">
        Также необходимо наличие действующего паспорта гражданина РФ.
      </p>
      <p class="featers__text">
        Обязательно нужно иметь мобильный телефон с выходом в интернет для
        регистрации и подтверждения личности.
      </p>
      <p class="featers__text">
        Для получения денежных средств и прохождения процедуры верификации
        понадобится именная банковская карта/счет.
      </p>
    </featers>
  </div>
</template>
<script>
import Banner from "../components/Banner.vue";
import Featers from "../components/Featers.vue";

export default {
  components: {
    Banner,
    Featers,
  },

  data() {
    return {
      btnShow: true,

      featers: [
        {
          text:
            "Удобный интерфейс позволит <br> быстро пройти процедуру <br> заполнения заявки <br> за несколько простых шагов",
          icon: "static/featers/Watches.svg",
          title: "Заполнение заявки <br> всего за 15 минут",
        },
        {
          text:
            "Вы можете получить займ <br>в любой точке РФ. <br>Все, что вам нужно –<br> доступ к интернету",
          icon: "static/featers/Map.svg",
          title: "Займы доступны <br> по всей России",
        },
        {
          text:
            "Мы гарантируем сохранность <br>личных данных. <br>Данные хранятся сервисом <br>в зашифрованном виде",
          icon: "static/featers/Security.svg",
          title: "Конфиденциально <br> и безопасно",
        },
      ],
      requirements: [
        {
          title: "От 18 лет",
          icon: "static/requirements/18.svg",
        },
        {
          title: "Паспорт РФ",
          icon: "static/requirements/Passport.svg",
        },
        {
          title: "Мобильный <br> телефон",
          icon: "static/requirements/Mobile.svg",
        },
        {
          title: "Банковская <br> карта",
          icon: "static/requirements/Card.svg",
        },
      ],
    };
  },
};
</script>
