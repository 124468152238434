<template>
    <section>
        <h2 class="offers__list-title">{{ title }}</h2>
        <ul class="offers__list">
            <li v-for="offer in offers" :key="offer.id">
                <OfferItem :offer="offer" :query="query"/>
            </li>
        </ul>
    </section>
</template>
<script>
    import OfferItem from './OfferItem.vue';

    export default {
        props: {
            title: String,
            offers: Object,
            query: String
        },
        components: {OfferItem}
    }
</script>