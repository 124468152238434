<template>
    <div class="offers" :class="{'offers--pseudo': loading}">
        <div v-show="loading" class="offers__loading" :style="{ width: loadingWidth + '%' }"></div>
        <div class="container">
            <div v-if="loading">
                <p class="offers__title">Подбор предложений
                    <img src="../../../assets/static/Arrows.svg" alt="" width="18" height="8">
                </p>
                <div class="offers__logo">
                    <img src="../../../assets/static/Spinner.gif" alt="" >
                </div>

            </div>

            <template v-else>
                <div v-if="userInfo" class="offers__user">
                    <h2 class="offers__user--header">{{ getGender(userInfo.gender) }} {{ userInfo.firstName}}&nbsp;{{userInfo.middleName}}!</h2>
                    <p class="offers__user--text1">Поздравляем! Ваша заявка успешно оформлена.</p>
                    <p class="offers__user--text2"> Учитывая Ваши требования: <strong>заём&nbsp;{{ (Number(userInfo.amount)).toLocaleString('ru')}}&nbsp;руб.&nbsp;на&nbsp;срок&nbsp;{{ userInfo.term}}&nbsp;{{decliningDays(userInfo.term, userInfo.acceptTerms)}},</strong>
                        система&nbsp;сформировала
                        варианты кредитных предложений,
                        которые будут одобрены именно Вам
                        с наибольшей вероятностью.</p>
                    <p class="offers__user--text3">*для увеличения вероятности получения займа, необходимо обратиться сразу в несколько кредитных организаций.</p>
                </div>
                <OffersList :offers="offers" :title="titleOffers" :query="query"/>
                <button type="button" @click="showAllOffers=true"  v-if="!showAllOffers" class="btn">Показать все предложения</button>
                <OffersList :offers="allOffers" :title="titleAllOffers" :query="query" v-if="showAllOffers"/>
            </template>
        </div>
    </div>
</template>
<script>
    import OfferItem from '../components/OfferItem.vue';
    import OffersList from "../components/OffersList.vue";

    export default {
        components: {
            OffersList,
            OfferItem
        },
        props: {
            url: String,
            query: String,
            user: String
        },
        data() {
            return {
                loadingWidth: 0,
                loadingStep: 32,
                loadingInterval: 750,
                info: null,
                loading: true,
                errored: false,
                myAttribute: 'defaultvalue',
                offers: null,
                allOffers: null,
                titleOffers: '',
                titleAllOffers: 'Все предложения:',
                showAllOffers: false,
                unitsDay: ['день', 'дня', 'дней'],
                unitsMonth: [ 'месяц',  'месяца', 'месяцев'],
                userInfo: null

            }
        },
        mounted() {

            axios.get(this.url).then(response => {

                this.userInfo = JSON.parse(this.user);

                this.info = response.data || {};
                this.offers = {}
                this.allOffers = {}

                if(this.userInfo!==null){
                    var age=this.getAge(this.userInfo.birthDate)
                    var term = this.userInfo.term
                    if(this.userInfo.acceptTerms=="month")
                        term = term*30

                    this.info[Object.keys(this.info)[0]].forEach((item, index)=>{
                        if(!((item.newSumTo<this.userInfo.amount&&item.newSumTo!==null)||(term>item.newTermTo&&item.newTermTo!==null)||(age<item.newAgeFrom&&item.newAgeFrom!==null)||(age>item.newAgeTo&&item.newAgeTo!==null)||(item.newSumFrom>this.userInfo.amount&&item.newSumFrom!==null))){
                            this.offers[index] = item
                        }
                    });
                    this.info[Object.keys(this.info)[1]].forEach((item, index)=>{
                        if(!((item.newSumTo<this.userInfo.amount&&item.newSumTo!==null)||(term>item.newTermTo&&item.newTermTo!==null)||(age<item.newAgeFrom&&item.newAgeFrom!==null)||(age>item.newAgeTo&&item.newAgeTo!==null)||(item.newSumFrom>this.userInfo.amount&&item.newSumFrom!==null))){
                            this.allOffers[index] = item
                        }
                    });
                }

                const interval = setInterval(() => {
                    if(this.loadingWidth < 100) {
                        this.loadingWidth += this.loadingStep-=4;
                    } else {
                        this.loading = false;
                        clearInterval(interval);

                        $('#offers').removeClass('loaded');
                    }
                }, this.loadingInterval-=50);

            }).catch(error => {
                console.error(error)
                this.errored = true
            });

        },
        methods: {
            getAge(value){
                var arrDate = value.split('.');
                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                var dob = new Date(arrDate[2], arrDate[1]-1, arrDate[0]);
                var dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
                var age;

                age = today.getFullYear() - dob.getFullYear();

                if (today < dobnow) {
                    age = age-1;
                }
                return age;

            },
            decliningDays(digit, acceptTerm)
            {
                var lastFigure = parseInt(digit.toString().substr(digit.toString().length - 1, 1));
                var term = this.unitsDay

                if(acceptTerm=="month")
                    term = this.unitsMonth
                if (digit >= 11 && digit < 15)
                {
                    return term[2];
                }
                else
                {
                    if (lastFigure == 1) return term[0];
                    if (lastFigure > 1 && lastFigure < 5) return term[1];
                    if (lastFigure == 0 || lastFigure >= 5) return term[2];
                }
            },
            getGender(gender){
                if(gender=='Ж')
                    return 'Уважаемая'
                else
                    return 'Уважаемый'
            }
        }
    }
</script>