import Vue from 'vue/dist/vue.js';
import './js/jquery.formstyler.min.js';
import FormCredit from './vue/pages/Form.vue';
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);

window.axios = require('axios');

Vue.component('form-credit', FormCredit);
new Vue({
    el: '#form-credit',
    data:{
        globalGender: 'НД',
        globalCityKladr: '0',
        globalRegion: '',
    },
});