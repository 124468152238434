<template>
    <section>
        <h2 class="offers__list-title">Вам подходят следующие предложения:</h2>
        <ul class="email_offers__wrapper">
            <li class="email_offers" v-for="offer in offers" :key="offer.id">
                <EmailOfferItem :offer="offer" :query="query"/>
            </li>
        </ul>
    </section>
</template>
<script>
    import EmailOfferItem from './EmailOfferItem.vue';

    export default {
        props: {
            title: String,
            offers: Array,
            query: String
        },
        components: {EmailOfferItem}
    }
</script>
