import Vue from 'vue/dist/vue.js';
import App from './vue/pages/App.vue';
import VueCurrencyFilter from 'vue-currency-filter';

Vue.use(VueCurrencyFilter,
{
  thousandsSeparator: ' ',
  fractionCount: 0,
  fractionSeparator: ',',
});

window.axios = require('axios');
Vue.component('app', App);
new Vue({
    el: '#app',
});

