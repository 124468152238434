<template>
    <div class="reviewer-success" @click.stop>
        <div class="reviewer-success__title">Спасибо!</div>
        <div class="reviewer-success__subtitle">Ваш отзыв успешно отправлен</div>

        <button class="reviewer-success__close g-button" @click="$emit('close')">
            Вернуться на главную
        </button>
    </div>
</template>

<script>
export default {
    name: 'ReviewerSuccess',
}
</script>
