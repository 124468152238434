<template>
	<div class="credit-form__input-group">
		<label class="credit-form__label">{{ label }}</label>
		<select :label="label" @input="setSelected" class="credit-form__input" required>
			<option v-for="option in options" :value="value">
				{{ option }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			default() {
				return []
			}
		},
		name: String,
		label: String,
		required: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			default() {
				return []
			}
		}
	},

	methods: {
		setSelected(value) {
			this.$emit('input', value ? value.value : null)
		}
	}
}
</script>
