<template>

    <div  ref="offer">
            <span class="email_offers__lable " ref="labelOffers">{{offer.label}}</span>
            <div class="email_offers__items-wrap">
                <a :href="url" class="email_offers__link"><img :src="offer.logo" width="250" height="85"></a>
            </div>
            <div class="email_offers__endorsement">
                <span class="email_offers__endorsement--name">Одобряемость</span>
                <span class="email_offers__endorsement--value">{{offer.approveLevel}}%</span>
            </div>
            <div class="email_offers__items-wrap">
                <p class="email_offers__item">
                    <span class="email_offers__name">Сумма</span>
                    <span class="email_offers__value">до {{offer.newSumTo}} &#8381;</span>
                </p>
                <p class="email_offers__item">
                    <span class="email_offers__name">Проценты</span>
                    <span class="email_offers__value">от {{offer.newPercentFrom}} %</span>
                </p>
                <p class="email_offers__item">
                    <span class="email_offers__name">Возраст</span>
                    <span class="email_offers__value">от {{offer.newAgeFrom}} лет</span>
                </p>
                <p class="email_offers__item">
                    <span class="email_offers__name">Срок</span>
                    <span class="email_offers__value">до {{offer.newTermTo}} дней</span>

                </p>
                <a :href="url" target="_blank" class="email_offers__btn btn">Получить займ</a>
            </div>
        </div>
</template>
<script>
    export default {
        props: {
            offer: Object,
            query: String
        },
        data() {
            return {
                url: ''
            }
        },
        mounted() {
            this.getUrl();

            if(this.offer.approveLevel==null){
                this.offer.approveLevel = Math.floor(Math.random() * (this.offer.approvePercentTo - this.offer.approvePercentFrom + 1)) + this.offer.approvePercentFrom;
            }



            if (!this.offer.label) {
                this.$refs.labelOffers.style.display = "none";
            } else {
                this.$refs.offer.classList.add('email_offers--' + this.offer.labelColor);
            }



        },
        methods: {
            getUrl() {
                this.url = this.offer.trackingLink;
                let uri = this.url.split('&');
                let newUrl = new URL(uri[0]);
                newUrl.searchParams.set('offer_id', this.offer.id);
                this.url = newUrl + '&' + this.query;
            }
        },
    }
</script>
