<template>
	<div class="banner">
		<div class="container">
			<div class="banner__form">
				<h1 class="banner__title">Универсальный<br> онлайн займ</h1>
				<ul class="banner__list">
					<li class="banner__item">Нужен только паспорт</li>
					<li class="banner__item">Получение с плохой кредитной историей</li>
					<li class="banner__item">Оформление за 15 минут</li>
					<li class="banner__item">первый займ от 0%*</li>
				</ul>
				<span  class="banner__range-label">Выберите необходимую сумму:</span>
				<span class="banner__range-sum">{{ sumCredit | currency }} &#8381;</span>
				<div class="banner__range">
					<div class="range__values">
						<span class="range__value range__value--start">1 000</span>
						<span class="range__value range__value--midle">50 000</span>
						<span class="range__value range__value--end">100 000</span>
					</div>
						<div class="range">
							<range-slider
							class="slider"
							min="1000"
							max="100000"
							step="1000"
							name="sum"
							v-model="sumCredit">
						</range-slider>
					</div>
				</div>

				<br><br>

				<span  class="banner__range-label">Выберите срок кредита:</span>
				<span class="banner__range-sum">{{ limitCredit / limitUnit | plural(limitUnit) }}</span>
				<div class="banner__range">
					<div class="range__values">
						<span class="range__value range__value--start">{{ limitMin / limitUnit | plural(limitUnit) }}</span>
						<span class="range__value range__value--end">{{ limitMax / limitUnit | plural(limitUnit) }}</span>
					</div>
						<div class="range">
							<range-slider
							class="slider"
							:min="limitMin"
							:max="limitMax"
							name="limit"
							:step="limitUnit"
							v-model="limitCredit">
						</range-slider>
					</div>
				</div>

				<div class="banner__info-cred">
					<p><span>Сумма</span><b>{{ sumCredit | currency }} &#8381;</b></p>
					<p><span>Срок</span><b>{{ limitCredit / limitUnit | plural(limitUnit) }}</b></p>
				</div>
				<a href="/application" class="btn banner__btn">Оформить заявку</a>
			</div>
		</div>
	</div>
</template>
<script>
import RangeSlider from 'vue-range-slider';

export default {
	components: {
		RangeSlider,
	},
	data() {
		return {
			sumCredit: 34000,
			limitCredit: 180,
			term: {
				quantity: 3, unit: 'month'
			},
		}
	},
	computed: {
		limitMin() {
			return this.sumCredit <= 30000 ? 5 : 60;
		},
		limitMax() {
			return this.sumCredit <= 30000 ? 30 : 360;
		},
		limitUnit() {
			return this.sumCredit <= 30000 ? 1 : 30;
		},
	},
	filters: {
		plural(val, unit) {
			if(unit === 1) {
				return `${ val } ` + getNoun(val, 'день', 'дня', 'дней');
			}
			if(unit === 30) {
				return `${ val } ` + getNoun(val, 'месяц', 'месяца', 'месяцев');
			}

			return val;
		}
	},
	watch: {
		sumCredit(newSumCredit) {
			localStorage.sumCredit = newSumCredit;

			if(newSumCredit <= 30000 && this.limitCredit > 30) {
				this.limitCredit = 30;
				localStorage.limitCredit = 30;
			}
			if(newSumCredit > 30000 && this.limitCredit < 60) {
				this.limitCredit = 60;
				localStorage.limitCredit = 60;
			}
		},
		limitCredit(newLimit) {
			localStorage.limitCredit = newLimit;
		}
	},
	mounted() {
		if (localStorage.sumCredit) {
			this.sumCredit = localStorage.sumCredit;
		}

		if(!localStorage.limitCredit) {
			if(this.sumCredit <= 30000) {
				this.limitCredit = 15;
				localStorage.limitCredit = 15;
				
			} else {
				this.limitCredit = 180;
				localStorage.limitCredit = 180;
			}
		} else {
			this.limitCredit = localStorage.limitCredit;
		}
	},
}

function getNoun(number, one, two, five) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
}
</script>