<template>
    <ul class="invalid-feedback" v-if="serverErrors">
        <li v-for="(serverError, index) in serverErrors" :key="index">
            <p v-if="serverError.field === nameField" >{{serverError.message}}</p>
        </li>
    </ul>
</template>
<script>
    export default {
        props: {
            serverErrors: Array,
            nameField: String
        }
    }
</script>